<template>
  <div class="overflow-hidden">
    <div class="container">
      <NuxtLink
        v-if="events.length && day"
        :to="localePath({name: 'schedule'})"
      >
        <UiInfo
          class="right-0 float-right mt-3 w-full md:w-auto"
          :content="$t('events.check_out', {date: day + ' ' + eventMonth})"
        />
      </NuxtLink>
      <div class="container-extend container-inset pb-7 pt-16">
        <FlowerSwiperCard
          v-model="currentMonth"
          :transition="true"
        />
      </div>
      <div class="block grid-cols-10 gap-4 pb-14 pt-4 lg:grid lg:pt-10">
        <div class="col-span-3 max-lg:hidden">
          <ThemeTrending />
        </div>
        <div class="col-span-4 flex items-center">
          <UiMonthNav
            v-model="currentMonth"
            class="mx-auto"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {useEventsQuery} from '~/graphql/graphql';

definePageMeta({
  middleware: 'sofie-page',
});

import {DateTime} from 'luxon';

const currentMonth = ref(DateTime.now());

const {result: eventsResult} = useEventsQuery({
  limit: 1,
});

const {locale} = useI18n();
const events = computed(() => eventsResult?.value?.events ?? []);

const date = new Date(events.value[0]?.date);
const day = date?.getDate();
const eventMonth = date?.toLocaleString(locale.value, {month: 'long'});
</script>
