<template>
  <div
    v-if="trendingThemes.length"
    class="inline-flex items-center gap-2 rounded-lg bg-secondary-700 p-4"
  >
    <div class="flex aspect-square h-8 w-8 items-center justify-center rounded-full bg-white">
      <UiIcon
        name="ArrowRight"
        class="-rotate-45 text-primary"
        size="small"
      />
    </div>
    <div class="text-sm font-medium">
      {{ $t('flower.collection.trending') }}
    </div>
    <div class="flex flex-wrap gap-1">
      <ThemeTag
        v-for="(theme, index) in trendingThemes"
        :key="index"
        :theme="theme"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {useTrendingThemesQuery} from '~/graphql/graphql';

const {result} = useTrendingThemesQuery();

const trendingThemes = computed(() => result.value?.trendingThemes ?? []);
</script>
